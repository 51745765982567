.consultation-item {
  background-color: #f9f9f9; /* Slight off-white background for the items */
  padding: 15px;
  margin-bottom: 10px; /* Spacing between items */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05); /* Soft shadow for depth */
  position: relative;
}
.delete-session-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px !important;
  margin: 0px;
  cursor: pointer;
  color: black;
  background-color: transparent; /* Removes any default background */
  border: none !important; /* Ensures there's no border */
  outline: none !important; /* Removes the focus outline */
}

/* Additional styles to ensure no highlighting or borders on click or focus */
/* .delete-session-button:focus,
.delete-session-button:active,
.delete-session-button:hover {
  background-color: rgb(158, 16, 16) !important;
  outline: none;
  border: none;
  box-shadow: none; /* Removes any box-shadow if present */
/* } */

.sidebar {
  height: calc(100% - 64px - 40px - 1px); /* 100% - header - 2x padding - hr */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Added */
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.consultations-list {
  flex: 1; /* Added */
  overflow-y: auto;
  padding: 5px 0;
}

.sidebar-footer {
  padding: 14px 0; /* Adjust padding as needed */
}


.settings-dropdown select,
.signout-button button {
  width: 100%; /* Adjust width considering padding */
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #d1d1d1; /* This applies only to the dropdown */
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  box-sizing: border-box; /* Add this line */
}

.signout-button button {
  background-color: #e0e0e0; /* Light grey background */
  cursor: pointer;
  margin: 10px 0; /* Adjust margin as needed */
}

.horizontal-bar {
  height: 1px; /* Adjust the height as needed */
  background-color: #333; /* Adjust the color as needed */
  width: 100%;
}