.note-container {
  height: calc(100vh - 340px);
  overflow: scroll;
  font-family: 'Arial', sans-serif;
  margin: 20px auto;
  background-color: #fff;
  padding: 20px; 
  border: 1px solid #fff; /* No border */
  border-radius: 4px;
}

.section {
  display: flex;
  flex-direction: column;
  padding: 10px 0; 
  width: 100%;
}

.section-header {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start; /* Aligns items to the left */
}

.section-footer {
  position: absolute;
  right: 25px;
  margin: 10px;
  bottom: 0;
}

.section-title {
  margin: 0;
  flex-grow: 0;
  color: rgb(109 117 141);
  font-size: 16px;
}

.copy-button {
  background-color: transparent;
  color: #333;
  border: none;
  cursor: pointer;
  opacity: 0; /* Keep it invisible until hover */
  transition: opacity 0.2s ease-in-out;
  margin-left: 10px; /* Adjust as needed to give space between the title and button */
}

.section:hover .copy-button {
  opacity: 1; /* Appear on hover */
}

.editable-content {
  width: 100%;
  /* Make the border color match the background to "hide" it */
  border: 1px solid #fff;
  box-sizing: border-box;
  /* Hide the resize handle */
  resize: none;
  /* Background color should match the note container */
  background-color: #fff;
  /* Text should look like normal text, not editable */
  outline: none;
  /* Match font styles with the rest of the note */
  font-family: 'Arial', sans-serif;
  font-size: 14px;
  color: #212a2f;
  /* Remove scrollbar, it will grow with content */
  overflow: hidden;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.no-data {
  text-align: center;
  color: #888;
  font-size: 18px;
}

textarea {
  width: 100%; /* Takes the full width of the section */
  border: none; /* Makes the border invisible */
  outline: none; /* Removes the outline to ensure focus does not introduce a border */
  resize: none; /* Prevents resizing, remove this if you want the user to resize */
  background-color: transparent; /* Makes the background transparent */
  font-family: 'Nunito', sans-serif;
  font-size: 13pt;
}


@media screen and (max-width: 1200px) {
  .note-container {
    height: calc(100vh - 380px);
  }
}

@media screen and (max-width: 900px) {
  .note-container {
    height: calc(100vh - 480px);
  }
}
@media screen and (max-width: 600px) {
  .note-container {
    height: calc(100vh - 380px);
  }
}


