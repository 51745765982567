/* App overall styling */
.app {
  font-family: 'Arial', sans-serif;
  display: flex;
  height: 100vh;
  padding: 0;
  margin: 0;
  overflow-x: hidden; 
  overflow-y: auto; 
  box-sizing: border-box;
  }

/* For the overall body */
body {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  font-size: 14px;
  margin: 0;
}

/* Sidebar styling */
.sidebar {
  background-color: #f8f8f8; /* Adjust the color as needed */
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.consultation-item {
  padding: 10px;
  margin-bottom: 10px;
  background-color: #ffffff;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
}

.consultation-item.active {
  border: 1px solid black

}

.consultation-item:hover {
  background-color: #f0f0f0;
}

/* Main content styling */
.main-content {
  flex-grow: 1;
  overflow-x: hidden; /* Prevent horizontal scrollbar */
  overflow-y: auto; /* Show vertical scrollbar only when needed */
  box-sizing: border-box; /* Include padding and border in the element's dimensions */
}


h1 {
  font-size: 24px;
}

h2 {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}

p {
  font-size: 14px;
  color: #666;
  line-height: 1.6;
}

.black-button {
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  background-color:  black; /* #4cae4c;  Adjust the color as needed */
  color: white;
  margin-bottom: 20px;
}

/* Footer styling */
.consultation-footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  border-top: 2px solid #e0e0e0;
}

.consultation-footer button {
  padding: 10px 20px;
  border: 1px solid #ccc; /* Light grey border */
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
  outline: none;
  margin-bottom: 20px;
}

.consultation-footer button:hover {
  background-color:  #007bff; /* #4cae4c;  Adjust the color as needed */
  color: white;
}

.tab-button.active {
  border-color: #007bff; /* Blue border for active tab */
  background-color: #007bff; /* Blue background for active tab */
  color: white;
}

/* Instructions Modal styling */
.instructions-modal {
  position: fixed;
  top: 0;
  right: 0;
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align to the top */
  z-index: 10; /* Ensure it's above other content */
}

.instructions-container {
  background:  #f8f8f8;
  padding: 20px;
  border-radius: 15px;
  width: 100%; /* Use the full width of the modal area */
  height: calc(100% - 40px); /* Subtract top and bottom padding */
  display: flex;
  flex-direction: column;
  position: relative; /* For absolute positioning of the close button */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 1.5rem;
  line-height: 1;
}


.instructions-container h2 {
  margin-bottom: 10px;
}

.instructions-container textarea {
  flex-grow: 1; /* Take available space */
  margin-bottom: 10px;
}

textarea {
  flex-grow: 1; /* This will make the textarea fill the available space */
  border: none; /* Makes the border invisible */
  outline: none; /* Removes the outline to ensure focus does not introduce a border */
  resize: none; /* Prevents resizing, remove this if you want the user to resize */
  padding: 15px;
  margin-bottom: 20px;
  background-color: ffffff; /* Makes the background white */
  border-radius: 12px;
  font-family: 'Nunito', sans-serif;
  font-size: 13pt;
  width: calc(100% - 20px);
}

.copy-note-button {
  margin-top: 10px; /* Space above the button */
}

.modal-footer {
  display: flex;
  justify-content: space-between;
}

.modal-footer button {
  padding: 10px 20px;
}

.auth-logo {
  height: 120;
  width: 120px;
}

