.nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f3f3f3;
    border-radius: 7px;
  }
  
  .nav-buttons-left, .nav-buttons-right {
    display: flex;
    gap: 10px; /* Spacing between elements */
  }
  
  .tab-button {
    padding: 8px 16px;
    margin: 0 5px;
    float: right;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: large;
  }
  
  .tab-button.active, .tab-button:hover {
    background-color: #007bff;
    color: white;
  }

  .tab-button.clipboard {
    position: fixed;
    bottom: 30px;
    right: 60px;
    z-index: 1000;
  }

  .tab-button.create-pdf { /* TODO: Reorganize HTML to avoid this class */
    position: fixed;
    bottom: 30px;
    right: 200px;
    z-index: 1000;
  }

  /* Specific styles for 'Record' and 'Generate Note' buttons */
  .record-button, .generate-button {
    padding: 10px 20px;
    border: none;
    border-radius: 7px; /* Rounded edges */
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .record-button {
    background-color: #f65f6e; /* Red for recording */
    color: white;
  }
  
  .record-button:hover {
    background-color: #c82333;
  }

  .recording-animation {
    max-height: 55px;
    max-width: 55px;
  }
  
  .generate-button {
    background-color: #28a745; /* Green for generating notes */
    color: white;
  }
  
  .generate-button:hover {
    background-color: #218838;
  }
  

  @media screen and (max-width: 1000px) {
    .nav-bar {
      display: block;
    }
    
    .nav-buttons-right {
      margin-top: 20px;
    }
  }