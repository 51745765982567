.header {
  display: flex; /* Establishes a flex container */
  flex-direction: column; /* Stacks children vertically */
  align-items: flex-start; /* Aligns children to the start of the flex container */
  width: 100%; /* Ensures the header takes the full width */
}

.header h2 {
  width: 100%; /* Ensures the h2 takes the full width of its parent */
  margin: 0; /* Removes default margin */
  display: flex; /* Allows the h2 to be a flex container as well */
}

.header h2 input[type="text"] {
  font-size: 1.5rem;
  border: none;
  outline: none;
  background: transparent;
  width: 100%; /* Takes the full width of its parent */
  display: block; /* Makes the input display as a block-level element */
  color: #333;
}
