/* TranscriptionTab.css */
.textareaStyle {
  min-height: calc(100vh - 260px);
  height: 100%;
  width: calc(100% - 20px); 
  display: flex;
  margin: 0;
}


@media screen and (max-width: 1200px) {
  .textareaStyle {
    min-height: calc(100vh - 330px);
  }
}

@media screen and (max-width: 900px) {
  .textareaStyle {
    min-height: calc(100vh - 370px);
  }
}

@media screen and (max-width: 600px) {
  .textareaStyle {
    min-height: calc(100vh - 380px);
  }
}
